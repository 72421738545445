$light-grayish-blue-semi-transparent-3: rgba(231,231,245,0.3);
$dark-graylish-blue: #9B9BAB;
$light-graylish-blue: #EFF0F8;
$white: #fff;
$medium-slate-blue: #6D71F9;
$very-dark-desaturated-blue: #272848;


$c-border-light-semi-transparent: $light-grayish-blue-semi-transparent-3;
$c-active-button-light: #999BFB;
$c-label-color: #9B9BAB;
$c-label-color-focus: #CCCCCC;
$c-main-gray: $dark-graylish-blue;
$main-color: $medium-slate-blue;
$c-main-light-gray: $light-graylish-blue;
$c-inscription-color: #9B9BAB;
$c-main-black: $very-dark-desaturated-blue;
$c-backlighting-blue: #e9eafe;

$g-black-blue: linear-gradient(110.67deg, #3A2748 12.35%, #3B3C72 76.14%);



/* COLORS */
$heading-brandcolor: #6D71F9;
$heading-black: #272848;
$heading-white: #fff;

$text-brandcolor: #6D71F9;
$text-black: #272848;
$text-white: #fff;

$link-heading: #272848;
$link-color: #6D71F9;
$link-hover: #0056b3;

$inscription-color: #9B9BAB;

$button-light-background: #E9EAFE;

$background-brandcolor: #6D71F9;
$background-dark: #272848;
$background-light: #EFF0F8;
$background-white: #fff;

$checked-input: #6D71F9;
$decoration-dgray: #E9EAFE;

$border-brandcolor: #6D71F9;
$border-gray: #9B9BAB;

$label-color: #9B9BAB;
$label-color-focus: #CCCCCC;