@import "../../../variables.scss";

.NotificationsModal__Overlay {
	position: fixed;
	inset: 0px;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.05);
	.NotificationsModal__Content {
		position: absolute;
		border: none;
		background: $background-white;
		overflow: auto;
		border-radius: 0 0 5px 5px;
		box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
		border: 1px solid $background-light;
		outline: none;
		width: 100%;
		max-height: 22em;
			@media (min-width: 576px) {
				width: auto;
				min-width: 30em;
			}
		a {
			&:focus {
				color: $link-color;
			}
		}
		.NotificationsModal__Inner {
		
			.mini-heading {
				text-transform: uppercase;
			}
			span {
				color: $inscription-color;
			}
		}
		
		h3 {
			font-size: 1.5em;
		}

	}
}