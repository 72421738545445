@import "../../../variables.scss";

.MobileMenuModal__Overlay {
	position: fixed;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.MobileMenuModal__Content {
		position: absolute;
		width: 80%;
		height: 100%;
		border: none;
		background: rgb(255, 255, 255);
		overflow: auto;
		border-radius: 0;
		outline: none;
		a {
			&:focus {
				color: $link-color;
			}
		}
		.MobileMenuModal__Content {
			span {
				color: #999;
			}
		}
		
		h3 {
			font-size: 1.5em;
		}

	}
	.warning {
		//color: $white;
			//opacity: 0.2;
			font-size: .875em;
			font-weight: 400;
			margin-bottom: 0em;
			text-transform: uppercase;
			width: 100%;
			display: block;
			color: $inscription-color;
			opacity: 0.5;
			&:first-child {
				font-size: 1.235em;
			}
	}
}